html,
body {
	font-size: 14px;
	height: 100%;
}

@media (max-width: 575px) {
	html,
	body {
		font-size: 12px;
	}
}

@font-face {
	font-family: 'Montserrat';
	src: url('./assets/montserrat.ttf');
}

p {
	margin-bottom: 0;
}

@font-face {
	font-family: 'Open Sans';
	src: url('./assets/open-sans.ttf');
}

#root {
	display: flex;
	flex-flow: column;
	height: 100%;
	font-family: 'Open Sans';
	color: rgb(97, 97, 97);
}

input,
button {
	color: rgb(97, 97, 97);
}

::placeholder {
	color: rgb(181, 181, 181);
}

.text-black {
	color: #000000;
}

.loading-bullet {
	font-size: 50px;
	animation: 2s ease infinite fadeInRTL1;
}

.rc-checkbox,
.rc-checkbox-input,
.rc-checkbox-inner {
	width: 18px;
	height: 18px;
}

.rc-checkbox-inner:after {
	width: 6px !important;
	height: 10px !important;
	left: 5px !important;
}

.swal-button:focus {
	box-shadow: none;
}

.swal-text {
	font-size: 20px;
	margin-top: 20px !important;
}

.swal-footer {
	margin-top: 0;
}

.rc-checkbox-checked .rc-checkbox-inner {
	border-color: rgb(77, 124, 254) !important;
	background-color: rgb(77, 124, 254) !important;
}

.Toastify__close-button {
	display: none;
}

.Toastify__toast {
	border-radius: 5px;
	background-color: #78cbf2;
	box-shadow: 0 4px 64px 4px rgba(0, 0, 0, 0.15);
	padding: 20px;
	margin: 20px;
}

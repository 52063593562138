input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 2px solid black;
  display: inline-block;
  font-size: 12px;
  padding: 9px 46px;
  cursor: pointer;
  color: #4d7cfe;
  font-weight: 600;
  /* flex-flow: row; */
}

.margin__top {
  margin-top: -25px;
}

.title-input {
  width: 100%;
  outline: none;
  border: none;
  height: 45px;
  padding-left: 10px;
}
